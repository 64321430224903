<template>
  <!-- BEGIN: Content-->
    <div class="app-content1 content1 ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow1"></div>
        <div class="content-wrapper container-xxl p-0" style="margin-top: 35px;">            
            <div class="content-body">

                <!-- Services start -->
                <div class="card">
                    <!-- <div class="card-header">
                        
                    </div> -->
                    <div class="card-body">

                        <div class="row justify-content-center">
                            <div class="col-12 col-md-12 col-lg-12 text-center">
                                

                                <h1 style="color: var( --e-global-color-secondary );
    font-family: 'Nunito', Sans-serif;
    font-size: 27px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    text-shadow: -1px 3px 3px rgb(0 0 0 / 30%);">WELCOME TO</h1>
                                <img src="https://apk.mudhrape.com/wp-content/uploads/2022/01/vakilgiri-300x72.png" width="200" />
                                <h2 style="color: var( --e-global-color-secondary );
    font-family: 'Nunito', Sans-serif;
    font-size: 27px;
    font-weight: 600;
    text-transform: lowercase;
    letter-spacing: 0.1px;
    text-shadow: -1px 3px 3px rgb(0 0 0 / 30%);">in association with</h2>
                                <img src="https://apk.mudhrape.com/wp-content/uploads/2022/01/3D-MUDHRAPE-LOGO-300x111.png" width="200" />
                                <h3 style="color: var( --e-global-color-secondary );
    font-family: 'Poppins', Sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2em;">LET US HELP YOU BECOME AN ENTREPRENEUR</h3>
    <br>
                                <p style="text-align: justify;
    color: var( --e-global-color-astglobalcolor8 );
    font-family: 'Poppins', Sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2em;">Vakilgiri is one of the best online tax and legal consultant in India occupying around 12% of the Indian Market share. We, the team of Vakilgiri, consisting of Chartered accountants, Business Experts, IT Developers &amp; Digital Marketers, work as a whole, thrives to provide all legal as well as IT/ Technical Consultancy under the same roof. We provide 360° solutions to start-ups and entrepreneurs by providing them guidance and support through various services such as Company Registration, Business and firm registration online, Online CA Services in India, <a href="https://www.vakilgiri.com/gst-registration/">GST Registration</a>, Trademark Registration, Web-Site Development, Mobile App Development, Digital Marketing, etc. at very nominal prices. We believe in building relationships with our Clients by providing them the Best online Tax and legal Consultant in India, and it’s our Clients who trusted us and aided us in growing continuously.</p>
                                <ul style="list-style-type: none;font-family: 'Poppins', Sans-serif;
    font-weight: 400;text-align: left;" >
                                    <li >
                                        <span class="elementor-icon-list-icon">
                                            <i class="text-danger" data-feather='check-square'></i> </span>
                                        <span class="elementor-icon-list-text"> I agree to <b>Terms &amp; Conditions</b> and <b>Privacy Policy</b> of Vakilgiri Legals.</span>
                                    </li>
                                </ul>

                                <button class="btn btn-danger" @click="allowTermCondition" >CONTINUE <i data-feather='arrow-right-circle'></i></button>

                            </div>
                        </div>

                    </div>
                </div>
                <!--/ Services start -->

            </div>
        </div>
    </div>
    <!-- END: Content-->
</template>

<script>
export default {
  name: 'Termcondition',
  methods: {
      allowTermCondition(){
          this.$router.push({name: 'Home'})
      }
  }
}
</script>
